import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/docs-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}>{`Overview`}<a parentName="h2" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`The Firstparty Event HTTP API is the lowest-level way of sending Events to Firstparty. SDKs, including Firstparty.js, rely
on the HTTP API as their method of sending data to Firstparty.`}</p>
    <p>{`This HTTP Source is useful when you want to send data directly to Firstparty without relying on an existing library or
when a library in your language doesn't exist. It's also helpful for debugging, testing, and prototyping new integrations.`}</p>
    <p>{`The Firstparty Event collection API has only two endpoints: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/events/v1/track`}</code>{` for sending a single Event, and
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/events/v1/track/bulk`}</code>{` for sending multiple Events in one payload. This may be different from what you've seen with
other analytics or measurement providers, and is designed to make data collection flexible and customizable for anyone
to configure at any time, rather than always requiring updates to code to make data collection changes.`}</p>
    <h2 {...{
      "id": "base-url",
      "style": {
        "position": "relative"
      }
    }}>{`Base URL`}<a parentName="h2" {...{
        "href": "#base-url",
        "aria-label": "base url permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`In order to collect Events with the HTTP Source, your client will make HTTP requests to the Domain you've configured
for your Source.`}</p>
    <p>{`For example, if the Domain you've configured is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`fp.rosebudmotels.com`}</code>{` you'll make an HTTP POST request to
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`fp.rosebudmotels.com/events/v1/track`}</code>{` to collect a new Event.`}</p>
    <h2 {...{
      "id": "authentication",
      "style": {
        "position": "relative"
      }
    }}>{`Authentication`}<a parentName="h2" {...{
        "href": "#authentication",
        "aria-label": "authentication permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`No authentication is required to collect Events with the HTTP API (unlike most other requests to the Firstparty API).`}</p>
    <h2 {...{
      "id": "request-requirements",
      "style": {
        "position": "relative"
      }
    }}>{`Request Requirements`}<a parentName="h2" {...{
        "href": "#request-requirements",
        "aria-label": "request requirements permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <ul>
      <li parentName="ul">{`The `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`content-type`}</code>{` header must be set to 'application/json'.`}</li>
      <li parentName="ul">{`A Write Key is required to be sent with every Event (more below).`}</li>
    </ul>
    <h2 {...{
      "id": "debugging",
      "style": {
        "position": "relative"
      }
    }}>{`Debugging`}<a parentName="h2" {...{
        "href": "#debugging",
        "aria-label": "debugging permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Every request to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/events/v1/track`}</code>{` endpoint will result in a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`200 OK`}</code>{` response. To debug your requests, use the
Debugger tab within the Source you're sending data to in the Firstparty UI.`}</p>
    <h2 {...{
      "id": "measurement-methods",
      "style": {
        "position": "relative"
      }
    }}>{`Measurement Methods`}<a parentName="h2" {...{
        "href": "#measurement-methods",
        "aria-label": "measurement methods permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`The HTTP API Source only collects Events via the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/events/v1/track`}</code>{` and`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/events/v1/track/bulk`}</code>{` endpoints.`}</p>
    <h3 {...{
      "id": "track-a-single-event",
      "style": {
        "position": "relative"
      }
    }}>{`Track a Single Event`}<a parentName="h3" {...{
        "href": "#track-a-single-event",
        "aria-label": "track a single event permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`To send a single Event to Firstparty, make an HTTP POST request to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/events/v1/track`}</code>{` that meets the requirements above,
along with a JSON object with the keys outlined below as necessary.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`POST https://fp.rosebudmotels.com/events/v1/track`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"profile_id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"PR123456789012345678901234567890"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"event"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Form Completed"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"properties"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"email"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"stevie@rosebudmotels.com"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Stevie Budd"`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"context"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"ip"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"12.34.56.78"`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <br />
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`track`}</code>{` endpoint accepts a JSON object with these fields:`}</p>
    <table>
      <tbody>
        <tr>
          <td>
            <code>event</code>
          </td>
          <td>required</td>
          <td>
The required name of the [Event](/docs/items/events/) to collect
          </td>
        </tr>
        <tr>
          <td>
            <code>profile_id</code>
          </td>
          <td>optional</td>
          <td>
The ID of the Profile generating the Event. If no Profile ID is provided, Firstparty will create a new Profile and return
the new Profile ID in the HTTP response.
          </td>
        </tr>
        <tr>
          <td>
            <code>session_id</code>
          </td>
          <td>optional</td>
          <td>
The ID of the Session associated with the Event. If a Session ID is not provided, Firstparty may create a new Session and return
the new Session ID in the HTTP response, depending on your Firstparty configuration.
          </td>
        </tr>
        <tr>
          <td>
            <code>properties</code>
          </td>
          <td>optional</td>
          <td>
An optional key-value object of Properties to collect along with the Event. If the Event name was "Form Completed", one property may be "email_address"
          </td>
        </tr>
        <tr>
          <td>
            <code>context</code>
          </td>
          <td>optional</td>
          <td>
An optional key-value object of context to describe where the Event was generated. <a href="/docs/items/events/#context">Read more about Context on the Event docs</a>.
          </td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      